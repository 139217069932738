import { FolderNode } from '../dataObjects/FolderNode';
import { Button } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';

interface Props {
    path: FolderNode[];
    onFolderChosen: (folder: FolderNode) => any;
}

const copyPath = (event: React.ClipboardEvent<HTMLDivElement>, props: Props) => {
    event.clipboardData.setData('text/plain', props.path.map(folder => folder.name).join('/').slice(props.path[0].name.length));
    event.preventDefault();
}

export function BreadcrumbComponent(props: Props) {
    let path = props.path;
    console.log("BreadcrumbComponent: path is " + path.map(folder => folder.name).join('/'));
    return (
        <div onCopy={ (event) => copyPath(event, props) }>
            {path.map(child =>
                <Button icon={IconNames.FolderOpen} key={child.id} className=" bp4-button bp4-minimal Area-button" onClick={() => props.onFolderChosen(child)}>
                    {child.name} {'>'}
                </Button>
            )}
        </div>
    );
};
