import React, { useEffect, useState } from 'react';
import { FolderNode } from '../dataObjects/FolderNode';
import { FileUpload } from './FileUpload';
import {
    Button,
    Classes,
    Dialog,
    DialogBody,
    DialogFooter,
    Label,
} from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';
import { CheckboxState, FileOperation } from '../util/util';
import { getNumberOfChildDocumentsFromServer } from '../workers/MdoApi';


interface Props {
    currentFolder: FolderNode;
    baseServerURL: string;
    selectAllCheckboxState: CheckboxState;
    sortOrder: string;
    currentUserIsAdmin: boolean;
    createFolder: (folderName: string) => void;
    childrenRefetchRequired: (bumpNumber?: number) => void;
    setSortOrder: (sortOrder: string) => void;
    setSelectAllCheckboxState: (checkboxState: CheckboxState) => void;
    setFileOperation: (fileOperation: FileOperation) => void;
    childrenRefetchRequiredCounter: number;
}

export function FolderToolbarComponent(props: Props) {
    const [showNewFolderDialog, setShowNewFolderDialog] = useState(false);
    const [numberOfChildDocuments, setNumberOfChildDocuments] = useState(0);
    const Copy = "Copy";
    const Move = "Move";
    const Delete = "Delete";
    const None = "None";

    useEffect(() => {
        if (props.currentUserIsAdmin) {
            console.log("useEffect called in FolderToolbarComponent to get the number of child documents for the current folder.");
            getNumberOfChildDocumentsFromServer(props.baseServerURL, props.currentFolder.id)
                .then(response => {
                    setNumberOfChildDocuments(response.numberOfChildDocuments);
                    console.log("numberOfChildDocuments set to " + response.numberOfChildDocuments);
                })
                .catch(ex => {
                    console.log("Failed to get the number of child documents for the current folder.");
                    console.log(ex);
                    if (ex.response?.status != 401){
                        // the user will be redirected to the logon page if the server returns a 401. No need to deal with that.
                        alert("Server error. Failed to get number of child documents for the current folder.");
                    }
                });
        }
    },
        [props.currentFolder, props.childrenRefetchRequiredCounter]
    )

    const onCreateFolderClicked = () => {
        setShowNewFolderDialog(true);
    }

    const createFolder = () => {
        const inputElement = document.getElementById("folder-name-input") as HTMLInputElement;
        const newFolderName = inputElement.value;
        console.log("New folder name: " + newFolderName)
        setShowNewFolderDialog(false);
        props.createFolder(newFolderName);
    }

    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            createFolder();
        }
    }

    const onSortOrderSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        props.setSortOrder(event.target.value);
    }

    const onSelectAllCheckboxChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            props.setSelectAllCheckboxState(CheckboxState.Checked);
        } else {
            props.setSelectAllCheckboxState(CheckboxState.Unchecked);
        }
    }

    const onFileOperationSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        if (event.target.value === Copy) {
            props.setFileOperation(FileOperation.Copy);
        } else if (event.target.value === Move) {
            props.setFileOperation(FileOperation.Move);
        } else if (event.target.value === Delete) {
            props.setFileOperation(FileOperation.Delete);
        }
    }

    useEffect(() => {
        if (props.currentUserIsAdmin){
            // console.log("FolderToolbarComponent useEffect: props.selectAllCheckboxState = " + props.selectAllCheckboxState + " (0=unchecked, 1=checked, 2=indeterminate)");
            const selectAllCheckbox = document.getElementById("Select-all-checkbox") as HTMLInputElement;
            if (props.selectAllCheckboxState === CheckboxState.Checked) {
                selectAllCheckbox.checked = true;
                selectAllCheckbox.indeterminate = false;
            } else if (props.selectAllCheckboxState === CheckboxState.Unchecked) {
                selectAllCheckbox.checked = false;
                selectAllCheckbox.indeterminate = false;
            } else {
                selectAllCheckbox.indeterminate = true;
            }
        // } else {
        //     console.log("FolderToolbarComponent useEffect: selectAllCheckbox not rendered because user is not admin");
        }
    }, [props.selectAllCheckboxState]);

    //   console.log("FolderToolbarComponent: props.selectAllCheckboxState = " + props.selectAllCheckboxState + " (0=unchecked, 1=checked, 2=indeterminate)");

    return (
        <>
            <Dialog title="Create Folder"
                icon={IconNames.FolderNew}
                isOpen={showNewFolderDialog}
                onClose={() => setShowNewFolderDialog(false)}>
                <DialogBody>
                    <Label>
                        New Folder Name:
                        <input id="folder-name-input" className={Classes.INPUT} placeholder="Enter folder name here" autoFocus onKeyPress={onKeyPress} />
                    </Label>
                </DialogBody>
                <DialogFooter actions={
                    <>
                        <Button intent="primary" text="Save" onClick={() => createFolder()} />
                        <Button intent="primary" text="Cancel" onClick={() => setShowNewFolderDialog(false)} />
                    </>
                } />
            </Dialog>

            <div id="Whole-folder-toolbar">
                {props.currentUserIsAdmin && <div id="Add-buttons_div">
                    <input type="checkbox" id="Select-all-checkbox" onChange={onSelectAllCheckboxChecked} />
                    <Button id="New-folder-button" icon={IconNames.FolderNew} className="bp4-button" onClick={onCreateFolderClicked}>
                        New Folder
                    </Button>
                    <FileUpload currentFolder={props.currentFolder} baseServerURL={props.baseServerURL} childrenRefetchRequired={props.childrenRefetchRequired}
                    />
                    <span id="file-operation-select" className="bp4-html-select">
                        <select id='file-operation-selector' value={None} onChange={onFileOperationSelected}>
                            <option value={None}>Selected documents...</option>
                            <option value={Copy}>Copy</option>
                            <option value={Move}>Move</option>
                            <option value={Delete}>Delete</option>
                        </select>
                        <span className="bp4-icon bp4-icon-double-caret-vertical"></span>
                    </span>
                    <span id="file-count"/>
                </div>}
                {(props.currentUserIsAdmin) && (numberOfChildDocuments > 0) && <span>
                    {numberOfChildDocuments} documents in folder
                </span>}
                <div id="Sort-order-select" className="bp4-html-select">
                    {/* <select value={props.currentServer} onChange={sortOrderSelected}> */}
                    {/* Folder Contents Sort Order: */}
                    <select value={props.sortOrder} onChange={onSortOrderSelected}>
                        <option value="title_asc">'A' first</option>
                        <option value="title_desc">'Z' first</option>
                        <option value="age_desc">newest first</option>
                        <option value="age_asc">oldest first</option>
                        <option value="size_desc">biggest first</option>
                        <option value="size_asc">smallest first</option>
                    </select>
                    <span className="bp4-icon bp4-icon-double-caret-vertical"></span>
                </div>
            </div>
        </>
    )
};
