import React from 'react';
import ReactDOM from 'react-dom/client';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import './css/index.css';
import App from './App';
import reportWebVitals from './setupStuff/reportWebVitals';

Bugsnag.start({
  apiKey: '403241b6f3d8ea4879632840b5b282cc',
  plugins: [new BugsnagPluginReact()],
  releaseStage: window.location.host
})

const bugsnagPlugin = Bugsnag.getPlugin('react')

if (bugsnagPlugin != null){

  // start the app running inside the BugSnag error boundary:
  const ErrorBoundary = bugsnagPlugin
    .createErrorBoundary(React)

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  );

} else {
  
  console.log("BugSnag Plugin is null. Running without BugSnag.")

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
  //   <React.StrictMode>
      <App />
  //   </React.StrictMode>
  );

}

console.log("index.tsx: New page rendering in process: "+window.location.host)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
