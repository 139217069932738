import React, { useEffect, useState } from 'react';
import { FolderNode } from '../dataObjects/FolderNode';
import Axios, { AxiosResponse } from 'axios';
import {
    CognitoUserPool,
    CognitoUser,
    CognitoUserSession,
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken
} from 'amazon-cognito-identity-js';
import {
    Button,
    Classes,
    Dialog,
    DialogBody,
    DialogFooter,
    Label,
    Menu, 
    MenuDivider,
    MenuItem, 
    Popover 
} from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';
import jwtDecode from 'jwt-decode';

interface Props {
    currentFolder: FolderNode;  
    currentServer: string;
    serverName: string;
    currentUserIsAdmin: boolean;
    onServerSelected: (serverName: string) => void;
    baseServerURL: string;
    setShowS3BrowseDialog: (showS3BrowseDialog: boolean) => void;
    search: (searchText: string) => void;
    createScanningCredentials: (folderId: string) => void;
    getBackgroundActivityInfo: () => void;
    resetUser: (username: string) => void;
    listUsers: () => void;
    createCognitoUserSheet: () => void;
    createBillingReport: () => void;
    showCombinerDialog: () => void;
    reindexPathsForDescendants: (folderId: string) => void;
}

interface MenuProps {
    baseServerURL: string;
}

const getRequestHeaders = () => {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const idToken = params.get('id_token');
    return {
        Authorization: 'Bearer ' + idToken,
    }
}

export function SearchBarComponent(props: Props) {
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const [cognitoClientInfo, setCognitoClientInfo] = useState({'COGNITO_CLIENT_ID': null,
                                                                'USER_POOL_ID': null});

    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const idToken = params.get('id_token');
    const accessToken = params.get('access_token');

    function serverSelected(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();
        props.onServerSelected(event.target.value);
    }

    const changePasswordButtonClicked = () => {
        setShowChangePasswordDialog(true);
        setPasswordError("");
    }

    if (cognitoClientInfo.COGNITO_CLIENT_ID == null) {
        const url = '/static/data/cognitoClientInfo.json';
        // get the cognitoClientInfo.json file from the server and read its contents:
        Axios.get(url, { responseType: 'blob', headers: getRequestHeaders() })
        .then(response => {
            const blob = new Blob([response.data], { type: 'application/json' });
            const reader = new FileReader();
            reader.onload = function() {
                const data = JSON.parse(this.result as string);
                setCognitoClientInfo(data);
            };
            reader.readAsText(blob);
        })
    }

    async function logout(menuProps: MenuProps) {
        if (cognitoClientInfo.COGNITO_CLIENT_ID != null) {
            const cognitoClientId = cognitoClientInfo.COGNITO_CLIENT_ID;
            const authDomainName = menuProps.baseServerURL.replace("-api", "-auth");
            let redirect_uri = window.location.href
            if (redirect_uri.includes("localhost") == false){
                redirect_uri = redirect_uri.replace("http://", "https://");
            }
            const loginURL = "https://"+authDomainName+"/logout?response_type=token&client_id="+cognitoClientId+"&redirect_uri="+redirect_uri;
            console.log("SearchBar: Redirecting to "+loginURL);
            window.location.href = loginURL;
        }else{
            console.error("Cannot change password yet. cognitoClientInfo:");
            console.error(cognitoClientInfo)
        }
    }

    const UserActionMenu = (menuProps: MenuProps) => {
        return (
            <Menu>
                <MenuItem text="Change Password" icon="edit" onClick={changePasswordButtonClicked} />
                <MenuItem text="Logout" icon="log-out" onClick={() => logout(menuProps)} />
                {props.currentUserIsAdmin && <MenuDivider />}
                {props.currentUserIsAdmin && (
                    <MenuItem 
                        text="Import From S3" 
                        icon="import" 
                        onClick={() => props.setShowS3BrowseDialog(true)} 
                    />
                )}
                {props.currentUserIsAdmin && (
                    <MenuItem 
                        text="Get Self-Scanning Credentials" 
                        icon="cube-add"
                        disabled={props.currentFolder.id === "0" || props.currentFolder.name === "Administration"} 
                        onClick={() => props.createScanningCredentials(props.currentFolder.id)}
                    />
                )}
                {props.currentUserIsAdmin && (
                    <MenuItem 
                        text="Get Background Processing Info" 
                        icon="waves"
                        onClick={() => props.getBackgroundActivityInfo()}
                    />
                )}
                {props.currentUserIsAdmin && (
                    <MenuItem 
                        text="Reset User" 
                        icon="people"
                        onClick={() => props.listUsers()}
                    />
                )}
                {props.currentUserIsAdmin && (
                    <MenuItem 
                        text="Create Sheet of Cognito Users" 
                        icon="heat-grid"
                        onClick={() => props.createCognitoUserSheet()}
                    />
                )}
                {props.currentUserIsAdmin && (
                    <MenuItem 
                        text="Kick Off Billing Report" 
                        icon="dollar"
                        onClick={() => props.createBillingReport()}
                    />
                )}
                {props.currentUserIsAdmin && (
                    <MenuItem 
                        text="Combine Documents In The Current Folder" 
                        icon="data-lineage"
                        onClick={() => props.showCombinerDialog()}
                    />
                )}
                {props.currentUserIsAdmin && (
                    <MenuItem 
                        text="Reindex Download Links and Paths For Children Of Current Folder"
                        icon="path-search"
                        onClick={() => props.reindexPathsForDescendants(props.currentFolder.id)}
                    />
                )}
            </Menu>
        );
    }

    const renderSelectIfLocalhost = (): any => {
        if (window.location.host === "localhost:3000") {
            return (
                <div className="bp4-html-select">
                    <select value={props.currentServer} onChange={serverSelected}>
                        <option value="Choose server...">Choose server...</option>
                        <option value="jfw">jfw</option>
                        <option value="jmv">jmv</option>
                        <option value="qa">qa</option>
                        <option value="www">www</option>
                    </select>
                    <span className="bp4-icon bp4-icon-double-caret-vertical"></span>
                </div>
            )
        };
    };

    const processChangePassword = (): void => {
        if ((cognitoClientInfo.COGNITO_CLIENT_ID != null) &&
                (cognitoClientInfo.USER_POOL_ID != null) && 
                (idToken != null) && 
                (accessToken != null)) {
            setPasswordError("");
            const oldPasswordElement = document.getElementById("old-password-input") as HTMLInputElement;
            const oldPassword = oldPasswordElement.value;
            const newPasswordElement = document.getElementById("new-password-input") as HTMLInputElement;
            const newPassword = newPasswordElement.value;
            const repeatNewPasswordElement = document.getElementById("repeat-new-password-input") as HTMLInputElement;
            const repeatNewPassword = repeatNewPasswordElement.value;
            if (newPassword != repeatNewPassword){
                console.log("The new passwords must match.");
                setPasswordError("The new passwords must match.");
            } else {
                const userPool = new CognitoUserPool({
                    UserPoolId: cognitoClientInfo.USER_POOL_ID,
                    ClientId: cognitoClientInfo.COGNITO_CLIENT_ID
                });

                const decodedToken: any = jwtDecode(idToken);
                const username = decodedToken['cognito:username'];
                const cognitoUser = new CognitoUser({
                    Username: username,
                    Pool: userPool
                });
                console.log("About to do the change password with the following idToken and accessToken:");
                console.log(jwtDecode(idToken));
                console.log(jwtDecode(accessToken));
                const userSession = new CognitoUserSession({IdToken: new CognitoIdToken({ IdToken: idToken }),
                                                            AccessToken: new CognitoAccessToken({ AccessToken: accessToken }),
                                                            RefreshToken: new CognitoRefreshToken({RefreshToken: ""})});
                cognitoUser.setSignInUserSession(userSession);

                cognitoUser.changePassword(oldPassword, newPassword, (error, result) => {
                    if (error) {
                        console.error("Attempt to change password failed with the following error:");
                        console.error(error);
                        setPasswordError(""+error);
                    }else{
                        console.log('Password change successful:', result);
                        setShowChangePasswordDialog(false);
                    }
                });
            }
        }else{
            console.error("Cannot change password yet. cognitoClientInfo:");
            console.error(cognitoClientInfo)
        }
    }

    const keyPressedInSearchBox = (event: any): void => {
        if (event.key === 'Enter') {
            props.search(event.target.value); 
        }
    }

    return (
        <>
            <Dialog title="Change Password"
                isOpen={showChangePasswordDialog}
                onClose={() => setShowChangePasswordDialog(false)}>
                <DialogBody>
                    <Label>
                        Old Password:
                        <input id="old-password-input" type="password" className={Classes.INPUT} placeholder="old password" autoFocus />
                        New Password:
                        <input id="new-password-input" type="password" className={Classes.INPUT} placeholder="new password" autoFocus />
                        Repeat New Password:
                        <input id="repeat-new-password-input" type="password" className={Classes.INPUT} placeholder="repeat new password" autoFocus />
                    </Label>
                    <p>Passwords must be at least 8 characters in length, and must have at least one of each of the following: upper and lower case letters, digits and special characters.</p>
                    {(passwordError.length>0) && <p style={{color: 'red'}}>{passwordError}</p>}
                </DialogBody>
                <DialogFooter actions={
                    <>
                        <Button intent="primary" text="Save" onClick={() => processChangePassword()} />
                        <Button intent="primary" text="Cancel" onClick={() => setShowChangePasswordDialog(false)} />
                    </>
                } />
            </Dialog>

            <Button icon="menu" className="takes-up-space-invisibly"/>
            <input type="text" className="{Classes.INPUT} Search" placeholder="Search..." onKeyDown={keyPressedInSearchBox}/>
            {/* With the advent of cognito and authentication, the baseServerURL didn't work. (Setting it to QA when running on localhost locked up the GUI).
            Fix it and uncomment this if it's ever needed. */}
            {/* {renderSelectIfLocalhost()} */}
            <Popover content={<UserActionMenu baseServerURL={props.baseServerURL}/>} placement="left">
                <Button icon="menu"/>
            </Popover>
        </>
    )
};
