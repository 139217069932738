import jwt_decode from 'jwt-decode';

export enum CheckboxState {
    Unchecked,
    Checked,
    Indeterminate
}

export enum FileOperation {
    Copy,
    Move,
    Delete,
    Rename,
    Merge,
    None
}

export enum CurrentUserIsAdmin {
    Yes,
    No,
    Unknown
}

export const getUsername = () => {
    interface TokenContainingName {
        email: string;
    }

    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);

    const idToken = params.get('id_token');
    if (idToken) {
        const decodedIdToken = jwt_decode<TokenContainingName>(idToken);
        let name = decodedIdToken['email'];
        if (name) {
            return name;
        }
    }

    const accessToken = params.get('access_token');
    if (accessToken) {
        const decodedAccessToken = jwt_decode<TokenContainingName>(accessToken);
        return decodedAccessToken['email'];
    }

    return 'unknown';
}
